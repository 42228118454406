<template>
  <div style="background-color: #ebf0ff; height: 100%">
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-layout wrap justify-center py-5>
      <v-flex xs10 sm4 md6 text-left>
        <span style="font-family: sedanRegular; color: #000000; font-size: 20px">View Transaction</span>
      </v-flex>
      <v-flex xs10 sm4 md2 text-left pa-2>
        <span style="font-family: sedanRegular; font-size: 14px; color: #000">Transaction Type</span>
        <v-select lable="Transaction Type" outlined dense v-model="transactionType" :items="statusList" item-text="text"
          item-value="value">
        </v-select>
      </v-flex>
      <v-flex xs10 sm4 md2 text-left pa-2>
        <span style="font-family: sedanRegular; font-size: 14px; color: #000">Asset Type</span>
        <v-select lable="Asset Type" outlined dense v-model="assetType" :items="assetStatus" item-text="text"
          item-value="value">
        </v-select>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pb-16 v-if="assetList.length > 0">
      <v-flex xs10 sm10 v-for="(item, i) in assetList" pa-2 :key="i">
        <v-card outlined v-bind:style="{ border: '1px solid #7CB9E8' }">
          <v-layout wrap py-4 justify-start px-5>
            <v-flex xs6 sm3 md3 lg3 xl3 text-left>
              <v-layout wrap>
                <v-flex xs12>
                  <span style="
                          font-family: montserratSemiBold;
                          font-size: 13px;
                          color: #000;
                        ">Asset Name</span>
                </v-flex>
                <v-flex xs12>
                  <span style="
                          font-family: montserratRegular;
                          font-size: 16px;
                          color: #000;
                        ">{{ item.assetName }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs6 sm1 md1 lg1 xl1 text-left>
              <v-layout wrap>
                <v-flex xs12>
                  <span style="
                          font-family: montserratSemiBold;
                          font-size: 13px;
                          color: #000;
                        ">Asset ID</span>
                </v-flex>
                <v-flex xs12>
                  <span style="
                          font-family: montserratRegular;
                          font-size: 16px;
                          color: #000;
                        ">{{ item.assetID }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs6 sm1 md1 lg1 xl1 text-left>
              <v-layout wrap>
                <v-flex xs12>
                  <span style="
                          font-family: montserratSemiBold;
                          font-size: 13px;
                          color: #000;
                        ">Asset Type</span>
                </v-flex>
                <v-flex xs12>
                  <span style="
                          font-family: montserratRegular;
                          font-size: 16px;
                          color: #000;
                        ">{{ item.assetType }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs6 sm2 md2 lg2 xl2 text-left>
              <v-layout wrap>
                <v-flex xs12>
                  <span style="
                          font-family: montserratSemiBold;
                          font-size: 13px;
                          color: #000;
                        ">Transaction Type</span>
                </v-flex>
                <v-flex xs12>
                  <span style="
                          font-family: montserratRegular;
                          font-size: 16px;
                          color: #000;
                        ">{{ item.transactionType }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs6 sm2 md2 lg2 xl2 text-left>
              <v-layout wrap v-if="item.transactionType == 'Transfer Units'">
                <v-flex xs12>
                  <span style="
                          font-family: montserratSemiBold;
                          font-size: 13px;
                          color: #000;
                        ">Transfer</span>
                </v-flex>
                <v-flex xs12
                  v-if="item.transactionType == 'Transfer Units' && (item.transferType == 'Transfer to user' || item.transferType == 'Sale')">
                  <span style="
                          font-family: montserratRegular;
                          font-size: 16px;
                          color: #000;
                        ">S : {{ item.noOfUnits }}</span>
                </v-flex>
                <v-flex xs12
                  v-if="item.transactionType == 'Transfer Units' && (item.transferType == 'Transfer to platform' || item.transferType == 'Purchase')">
                  <span style="
                          font-family: montserratRegular;
                          font-size: 16px;
                          color: #000;
                        ">P : {{ item.noOfUnits }}</span>
                </v-flex>
              </v-layout>
              <v-layout wrap v-else>
                <v-flex xs12>
                  <span style="
                          font-family: montserratSemiBold;
                          font-size: 13px;
                          color: #000;
                        ">Transfer</span>
                </v-flex>
                <v-flex xs12>
                  <span>--</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs6 sm1 md1 lg1 xl1 text-left>
              <v-layout wrap>
                <v-flex xs12>
                  <span style="
                          font-family: montserratSemiBold;
                          font-size: 13px;
                          color: #000;
                        ">Date</span>
                </v-flex>
                <v-flex xs12>
                  <span style="
                          font-family: montserratRegular;
                          font-size: 16px;
                          color: #000;
                        ">{{ formatDate(item.create_date) }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm2 md2 lg2 xl2 text-right px-5>
              <v-layout wrap>
                <v-flex xs12 pt-4>
                  <v-btn dark small rounded color="#6AAFEE" :to="'/viewAsset?id=' + item._id">
                    <span style="
                            text-transform: none;
                            font-family: montserratRegular;
                          ">View
                    </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pb-16 v-else>
      <v-flex xs12>
        <span style="font-family: sedanRegular; font-size: 20px; color: #000">No data found ...
        </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <div class="text-center pb-5" v-if="pages > 1">
          <v-pagination :length="pages" :total-visible="7" v-model="currentPage" color="#6AAFEE"></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    page: 1,
    currentPage: 1,
    pages: 0,
    limit: 10,
    appLoading: false,
    ServerError: false,
    showSnackBar: false,
    msg: null,
    timeout: 5000,
    assetList: [],
    statusList: [
      { value: "", text: "All" },
      { value: "Register", text: "Register" },
      { value: "Update", text: "Update" },
      { value: "Transfer", text: "Transfer" },
      { value: "Transfer Units", text: "Transfer Units" },
    ],
    transactionType: "",
    assetStatus: [
      { value: "", text: "All" },
      { value: "Land", text: "Land" },
      { value: "Units", text: "Units" },
    ],
    assetType: "",
  }),
  watch: {
    transactionType() {
      this.getAsset();
    },
    assetType() {
      this.getAsset();
    },
    currentPage() {
      this.$store.commit("changeCurrentPage", this.currentPage);
      this.getAsset();
    },
  },
  beforeMount() {
    if (this.$store.state.currentPage) {
      this.currentPage = this.$store.state.currentPage;
      this.getAsset();
    } else this.getAsset();

  },
  methods: {
    getAsset() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/own/assets/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          status: "Active",
          transactionType: this.transactionType,
          assetType: this.assetType,
          limit: this.limit,
          page: this.currentPage,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.assetList = response.data.data;
            this.pages = Math.ceil(response.data.totalLength / this.limit);
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();

      var strTime = day + " " + dt.slice(4, 7) + " " + year;

      return strTime;
    },
  },
};
</script>
